@import '../../constants.scss';

.resume--container {
  background-color: var(--alternate-section-bg);
  min-height: 100vh;
  max-width: 1028px;
  margin: auto;
  margin-top: 100px;
  padding: 2rem;

  .resume-header-section {
    .flex-container {
      align-items: center;
      display: flex;
      justify-content: space-between;
      gap: 2rem;

      .left-side-section {
        width: calc(100% - 200px);

        .name-heading {
          color: var(--app-text);
          font-size: 2.5rem;
          font-weight: 600;
        }

        .tagline-heading {
          margin-top: 0.25rem;
          color: $theme-color;
          font-size: 1.25rem;
          font-weight: 500;
        }

        .contact-info-flex-container {
          margin-top: 1rem;
          align-items: center;
          display: flex;
          gap: 1rem;
          width: 100%;

          .info-item {
            width: 100%;

            a {
              align-items: center;
              display: flex;
              gap: 0.5rem;
              width: 100%;
              text-decoration: none;
              color: var(--desc-text) !important;
              font-size: 1rem;
              font-weight: 500;

              i {
                min-width: 1rem;
                font-size: 1.25rem;
                color: $theme-color;
              }

            }
          }
        }
      }

      .right-side-section {
        .profile-image-section {
          img {
            border-radius: 50%;
            height: 150px;
            width: 150px;
            object-fit: cover;
            border: 4px solid $theme-color;
          }
        }
      }
    }
  }

  .info-flex-container {
    display: flex;
    justify-content: space-between;
    gap: 2rem;
    margin-top: 1.5rem;

    .left-side-section {
      width: 55%;

      .experience-info-container {
        margin-top: 1rem;

        .experience-section {
          margin-top: 0.5rem;

          .role-heading {
            font-size: 1.25rem;
            font-weight: 600;
            color: var(--app-text);
          }

          .company-name {
            margin-top: 0.25rem;
            align-items: center;
            display: flex;
            justify-content: space-between;

            a {
              color: $theme-color;
              font-size: 1.125rem;
              font-weight: 600;
            }

            .time-frame {
              font-weight: 500;
              font-size: 0.875rem;
              color: var(--desc-text);
            }
          }

          ul {
            li {
              margin-top: 0.25rem;
              line-height: 1.75;
              font-size: 18px;
              color: var(--desc-text);
              font-weight: 400;
              font-family: "Mulish", sans-serif;
            }
          }
        }
      }
    }

    .right-side-section {
      width: 45%;

      .skills-section-container {
        .tech-stack-container {
          .content-section {
            padding: 0;
            background: transparent;

            .heading {
              display: none;
            }

            .flex-section {
              margin-top: 0;

              .skill-card {
                width: 25%;
                min-width: unset;
                padding: 0;
                margin: 0;
                margin-top: 28px;
                background-color: transparent !important;

                .image-section {
                  margin-bottom: 0.25rem;

                  img {
                    max-width: 100%;
                    max-height: 60px;
                    object-fit: contain;
                    aspect-ratio: 3/2;
                  }
                }

                span {
                  color: var(--desc-text);
                  font-weight: 600;
                  font-size: 0.875rem;
                }
              }
            }
          }
        }
      }

      .education-details-container {
        margin-top: 1.5rem;

        .section-heading {
          margin-bottom: 1rem;
        }

        .info-section {
          margin-bottom: 1.5rem;

          .year {
            color: var(--desc-text);
            font-weight: 500;
            margin-bottom: 0.5rem;
          }

          .course-name {
            color: $theme-color;
            font-weight: 600;
            font-size: 1.125rem;
            margin-bottom: 0.25rem;
          }

          .college-name {
            a {
              color: var(--app-text);
            }
          }
        }
      }

      .certificate-info-section {
        color: var(--app-text);
        margin-bottom: 1rem;

        .name {
          font-size: 1.125rem;
          color: var(--app-text);
          margin-bottom: 0.25rem;
        }

        .source {
          a {
            color: $theme-color;
            font-weight: 500;
          }
        }
      }

      .tags-container {
        margin-top: 1.5rem;
        align-items: center;
        display: flex;
        gap: 1rem;

        .tag {
          border: 2px solid $theme-color;
          padding: 0.5rem 1rem;
          border-radius: 5px;
          color: var(--app-text);
          font-weight: 600;
        }
      }
    }

    .extra-text {
      color: var(--desc-text);
    }

    .languages-section {
      margin-top: 2rem !important;
    }

    .declaration-section {
      margin-top: 1.5rem;

      .section-desc {
        margin-top: 1rem;
        color: var(--desc-text);
        line-height: 1.75;
        font-size: 18px;
        font-weight: 400;
        font-family: "Mulish", sans-serif;
      }

      .sign-name-text {
        margin-top: 1.5rem;
        color: $theme-color;
        text-align: right;
        font-weight: 600;
        font-size: 1.25rem;
      }
    }

    .section-heading {
      font-weight: 800;
      font-size: 1.5rem;
      text-transform: uppercase;
      color: var(--desc-text);
      border-bottom: 2px solid var(--desc-text);
      padding-bottom: 0.5rem;
    }
  }

  .summary-info-container {
    .summary-desc {
      margin-top: 1rem;
      line-height: 1.75;
      font-size: 18px;
      color: var(--desc-text);
      font-weight: 400;
      font-family: "Mulish", sans-serif;
    }
  }
}

@media screen and (max-width: 767px) {
  .resume--container {
    margin-top: 50px;

    .resume-header-section {
      .flex-container {
        width: 100%;
        flex-wrap: wrap;
        flex-direction: column-reverse;

        .left-side-section {
          width: 100%;
        }

        .right-side-section {
          width: 100%;
          justify-content: center;
          align-items: center;
          display: flex;

        }

        .name-heading {
          font-size: 1.5rem !important;
          text-align: center;
        }

        .tagline-heading {
          line-height: 1.5 !important;
          text-align: center;
          font-size: 1.125rem !important;
        }
      }

      .contact-info-flex-container {
        display: block !important;

        .info-item {
          margin-bottom: 1rem !important;
        }
      }
    }

    .info-flex-container {
      display: block;

      .left-side-section {
        width: 100%;
      }

      .right-side-section {
        width: 100%;

        .flex-section {
          .skill-card {
            width: 33% !important;
          }
        }
      }
    }
  }
}