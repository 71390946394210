@import '../../../constants.scss';

.app-testimonials {
  margin-bottom: 3rem;

  .content-section {
    max-width: $max-width;
    margin: 0 auto;
    border-radius: 5px;

    .at-heading {
      text-align: center;
      font-size: 36px;
      color: var(--app-text);
      margin: 0;
    }

    .card-main-container {
      position: relative;

      .arrows-section {
        position: absolute;
        width: 100%;
        top: calc(50% - 1.25rem);
        display: flex;
        justify-content: space-between;

        .left-arrow,
        .right-arrow {
          align-items: center;
          display: flex;
          cursor: pointer;
          justify-content: center;
          width: 2.5rem;
          height: 2.5rem;
          background-color: $theme-color;
          color: white;
        }

        .left-arrow {
          border-top-right-radius: 2px;
          border-bottom-right-radius: 2px;
        }

        .right-arrow {
          border-top-left-radius: 2px;
          border-bottom-left-radius: 2px;
        }
      }
    }

    .testimonial-card {
      align-items: stretch;
      display: flex;
      border-radius: 10px;
      width: 100%;
      background-color: var(--alternate-section-bg);
      margin: 40px auto;
      padding: 3rem;

      .tc-heading {
        text-align: center;
        font-size: 16px;
      }

      .user-info-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        margin-top: 0px;
        min-width: 200px;
        padding-right: 40px;
        border-right: 1px solid var(--border-right-color);

        .image-section {
          width: 80px;
          height: 80px;

          img {
            width: 100%;
            object-fit: contain;
            border-radius: 50%;
          }
        }

        .info-section {
          max-width: 400px;
          display: flex;
          flex-direction: column;
        }
      }

      .tc-desc {
        padding-left: 40px;
        font-weight: 300;
        color: var(--app-text);
        line-height: 1.75;
        margin: auto;
        font-family: 'Mulish', sans-serif;
        font-size: 18px;
        max-width: 80%;
      }

      .tc-name {
        margin: 0;
        margin-top: 1rem;
        text-align: center;
        color: $active-theme-color;
        font-weight: 600;
        font-size: 18px;
      }

      .tc-role {
        margin-top: 0.25rem;
        text-align: center;
        font-size: 14px;
        font-weight: 500;
        color: var(--desc-text);
      }
    }

    .dots-section {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 1rem;

      .dot-item {
        border: 1px solid var(--alternate-section-bg);
        background-color: var(--body-background);
        border-radius: 2px;
        cursor: pointer;
        height: 0.75rem;
        width: 0.75rem;

        &.active-item {
          background-color: $theme-color;
          border-color: $theme-color;
        }
      }
    }
  }
}

@media screen and (max-width: 767px) {
  .app-testimonials {
    .content-section {
      padding: 20px;
      padding-top: 40px;

      .at-heading {
        font-size: 28px;
      }

      .testimonial-card {
        width: 100%;
        margin: 10px auto;
        margin-top: 40px;
        margin-bottom: 1rem;
        padding: 1.25rem;
        flex-direction: column-reverse;

        .user-info-container {
          border-right: 0;
          padding-right: 0;
          padding-top: 1rem;
          border-top: 1px solid var(--border-right-color);
        }

        .tc-heading {
          text-align: center;
          font-size: 16px;

        }

        .tc-desc {
          line-height: 1.8;
          max-width: 95%;
          padding-bottom: 1rem;
          padding-left: 0;
        }

        .tc-role {
          line-height: 1.5;
        }
      }
    }
  }
}