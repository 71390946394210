@import '../../../constants.scss';

.worked-with-container {
  .content-section {
    max-width: $max-width;
    margin: 0 auto;
    padding: 7rem 0;

    .heading {
      text-align: center;
      font-size: 36px;
      color: var(--app-text);
      margin: 0;
    }

    .flex-section {
      display: flex;
      justify-content: center;
      align-items: stretch;
      flex-wrap: wrap;
      margin-top: 80px;
      gap: 1rem;

      .skill-card {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        border-radius: 5px;
        flex: 1;
        padding: 1rem 0;

        .image-section {
          img {
            max-width: 100%;
            max-height: 120px;
            object-fit: contain;
            aspect-ratio: 3/2;
            filter: grayscale(1);

          }
        }

        &:hover {
          border-radius: 5px;
          transform: scale(1.01);
          opacity: 0.89;
          background-color: var(--alternate-section-bg);

          img {
            filter: unset;
          }
        }

        span {
          color: var(--app-text);
          font-weight: 600;
        }
      }
    }
  }
}

@media screen and (max-width: 767px) {
  .worked-with-container {
    .content-section {
      padding: 20px;
      padding-top: 40px;

      .heading {
        font-size: 28px;
      }

      .flex-section {
        margin-top: 20px;

        .skill-card {
          width: 100%;
          min-width: 45%;
        }
      }
    }
  }
}

:root[data-theme='dark'] {
  .worked-with-container {
    .content-section {
      .flex-section {
        .skill-card {
          .image-section {
            img {
              filter: invert(0.15);
              mix-blend-mode: plus-lighter;
            }
          }
        }
      }
    }
  }
}