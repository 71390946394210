@import '../../../constants.scss';

header.app-header {
  position: fixed;
  top: 0.5rem;
  right: 0.5rem;
  z-index: 1025;
  border-radius: 50%;

  .content {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    max-width: $max-width;
    margin: auto;
    width: 5rem;
    height: 5rem;

    .left-side {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;

      .sk--logo-section {
        color: var(--app-text);
        text-decoration: none;

        .sk--square {
          width: 50px;
          height: 48px;
          border: 5px solid $active-theme-color;
          border-radius: 3px;
        }

        .sk--text {
          background-color: var(--header-background);
          padding: 6px;
          font-size: 22px;
          z-index: 1000000;
          position: absolute;
          top: 15px;
          left: -5px;
          font-weight: 600;
          font-weight: 600;
        }
      }
    }

    .right-side {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      position: relative;

      ul {
        background-color: var(--header-background);
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        padding: 0;
        margin: 0;
        list-style-type: none;

        li {
          margin: 0 1rem;

          a {
            color: var(--app-text);
            text-decoration: none;
            font-size: 20px;
            font-weight: 500;
            transform: rotate(180deg);
          }

          &:hover {
            transform: scale(1.1);

            a {
              color: $active-theme-color;
            }
          }

          &:last-child {
            margin-right: 0;
          }

          i {
            color: var(--app-text);
            font-size: 24px;
            margin: 0 15px;
            cursor: pointer;
          }
        }

        li.active-link {
          a {
            color: $active-theme-color;
            font-weight: 600;
          }
        }
      }
    }
  }

}

header.app-secondary-header {
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background-color: var(--body-background);
  z-index: 1025;

  .content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1rem 0;
    max-width: $max-width;
    margin: auto;
    height: 80px;

    .left-side {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;

      .sk--logo-section {
        color: var(--app-text);
        text-decoration: none;

        .sk--square {
          width: 50px;
          height: 48px;
          border: 5px solid $active-theme-color;
          border-radius: 3px;
        }

        .sk--text {
          background-color: var(--body-background);
          padding: 6px;
          font-size: 22px;
          z-index: 1000000;
          position: absolute;
          top: 15px;
          left: -5px;
          font-weight: 600;
          font-weight: 600;
        }
      }
    }

    .right-side {
      display: flex;
      justify-content: flex-end;

      .toggle-them-icon {
        color: var(--app-text);
        text-decoration: none;
        font-size: 24px;
        font-weight: 600;
      }
    }
  }
}

header.app-header {
  .content {
    .right-side {
      .menu-icon {
        z-index: 1026;

        img {
          cursor: pointer;
          filter: invert(1);
          width: 2.5rem;
          height: 2.5rem;
        }
      }

      ul {
        position: fixed;
        top: 0;
        left: 0;
        z-index: -1;
        width: 100%;
        height: 100vh;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        li {
          margin: 20px 0;

          a {
            color: var(--app-text);
            text-decoration: none;
            font-size: 20px;
            font-weight: 500;

            i {
              font-size: 2rem;
            }
          }

          &:hover {
            transform: scale(1.1);

            a {
              color: $theme-color;
            }
          }

          &:last-child {
            margin-right: 0;
          }

          i {
            color: var(--app-text);
            font-size: 2rem;
            margin: 0 15px;
            cursor: pointer;
          }
        }

        li.active-link {
          a {
            color: $theme-color;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 767px) {
  header.app-header {
    width: 100%;
    justify-content: flex-end;
    display: flex;
    top: 0;
    right: 0;
    border-bottom: 1px solid var(--header-border-color);
    border-radius: 0;
    background-color: var(--app-text-shadow);

    .content {
      height: 3rem;
      padding: 0 1rem;
      width: 100%;
      justify-content: flex-end;

      .right-side {
        .menu-icon {
          z-index: 1026;

          img {
            cursor: pointer;
            filter: invert(1);
            width: 2rem;
          }
        }

        ul {
          position: fixed;
          top: 0;
          left: 0;
          z-index: -1;
          width: 100%;
          height: 100vh;
          flex-direction: column;
          justify-content: center;
          align-items: center;

          li {
            margin: 20px 0;

            a {
              color: var(--app-text);
              text-decoration: none;
              font-size: 20px;
              font-weight: 500;

              i {
                font-size: 2rem;
              }
            }

            &:hover {
              transform: scale(1.1);

              a {
                color: $theme-color;
              }
            }

            &:last-child {
              margin-right: 0;
            }

            i {
              color: var(--app-text);
              font-size: 2rem;
              margin: 0 15px;
              cursor: pointer;
            }
          }

          li.active-link {
            a {
              color: $theme-color;
            }
          }
        }
      }
    }
  }
}


:root[data-theme='light'] {
  header.app-header {
    .content {
      .right-side {
        .menu-icon {
          img {
            cursor: pointer;
            filter: unset;
          }
        }
      }
    }
  }
}