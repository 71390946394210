@import '../../../constants.scss';

.social-icons-section {
  padding-top: 10px;

  a {
    display: inline-flex;
    width: 50px;
    height: 50px;
    margin-right: 15px;
    border-radius: 50%;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    color: var(--app-text);
    background-color: var(--alternate-section-bg);
    font-size: 28px;
    transition: background-color 0.1s 0.1s ease-in-out;

    &:hover {
      background-color: $theme-color;
    }

    &:last-child {margin-right: 0;}
  }
}

@media screen and (max-width: 767px) {
  .social-icons-section {
    a {
      width: 40px;
      height: 40px;
      margin-right: 10px;
    }
  }
}
