@import url('https://fonts.googleapis.com/css2?family=Plus+Jakarta+Sans:wght@200;300;400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Mulish:wght@200;300;400;500;600&display=swap');
@import url('https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.css');


@import './constants.scss';

html {
  --black: #262d50;
  --alternate-section-bg: #191919;
  --app-text: #c7d8eb;
  --sub-heading-text: #a1a2a2;
  --app-button-text: #e1e1e1;
  --app-seoncdary-button-text: #313232;
  --secondary-button-bg: #001439;
  --app-sub-text: #C7D8F1;
  --desc-text: #C7D8F1;
  --theme-color: red;
  --body-background: #060606;
  --header-background: #060606;
  --app-text-shadow: #060606;
  --image-background: transparent;
  --header-border-color: #101d35;
  --border-right-color: #393939;
}

html[data-theme='light'] {
  --black: #262d50;
  --alternate-section-bg: #d3e3f1;
  --app-text: #000;
  --sub-heading-text: #323232;
  --app-button-text: #f1f1f1;
  --app-seoncdary-button-text: #313131;
  --app-sub-text: #616262;
  --secondary-button-bg: #101d35;
  --header-background: #d3e3f1;
  --theme-color: red;
  --body-background: #edf3ff;
  --desc-text: #2B2B2B;
  --app-text-shadow: #e0e9f1;
  --image-background: #333;
  --header-border-color: #e0e9f1;
  --border-right-color: #adbecc;
}

body {
  margin: 0;
  background-color: var(--body-background);
  font-family: 'Plus Jakarta Sans', sans-serif;

  * {
    box-sizing: border-box;
    outline: none;
  }

  .date-picker-container {
    .floating-datepicker {
      z-index: 1010;
    }
  }

  .button-section {
    margin-top: 30px;
    position: relative;
    margin: 30px 0;

    button {
      background-color: $theme-color;
      height: 45px;
      border: none;
      border-radius: 2px;
      width: 200px;
      font-size: 16px;
      cursor: pointer;
      outline: none;
      color: var(--app-button-text);
      font-family: 'Plus Jakarta Sans', sans-serif;
      font-weight: 600;
      transition: opacity 0.2s 0.2s ease-in-out;
      position: relative;
      z-index: 1005;

      a {
        color: var(--app-button-text);
        text-decoration: none;
      }

      &:hover {
        opacity: 0.75;
      }

      &:disabled {
        opacity: 0.5;
        cursor: not-allowed;
      }
    }

    button.secondary {
      background-color: var(--secondary-button-bg);

      a {
        color: var(--app-button-text);
      }
    }

    button:hover+.span-after {
      width: 250px;
      display: none;
    }

    .span-after {
      display: none;
      width: 200px;
      height: 45px;
      background-color: var(--desc-text);
      position: absolute;
      right: 0px;
      top: 8px;
      z-index: 10;
    }
  }
}