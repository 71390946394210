@import '../../constants.scss';

.app-landing-section {
  color: var(--app-text);
  display: flex;
  flex-direction: column;

  .sub-heading {
    margin: 0;
    font-weight: 600;
    font-size: 16px;
    color: #515252;
  }

  .heading {
    margin: 15px 0;
    font-size: 64px;
    font-weight: 800;
  }

  .desc {
    margin: 0;
    font-weight: 300;
    font-size: 20px;
    font-family: 'Mulish', sans-serif;
  }
}

.about-me-section {
  background-color: #f0cdcb;
  max-width: $max-width;
  margin: 7rem auto 7rem;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: stretch;
  flex-direction: row-reverse;
  gap: 0 1rem;

  .left-side {
    min-width: 300px;
    width: auto;
    display: flex;
    align-items: stretch;

    img {
      max-height: 500px;
      position: relative;
      z-index: 1000;
      border-radius: 6px;
      object-fit: cover;
    }
  }

  .right-side {
    flex: 1;
    padding: 3rem;
    border-radius: 5px;

    .heading {
      font-size: 42px;
      font-weight: 600;
      // text-shadow: 0 0 15px var(--app-text-shadow);
      line-height: 1.5;
      color: #262626;
      margin: 0;
    }

    .cta-main-container {
      align-items: center;
      display: flex;
      gap: 1rem;
      margin-top: 1rem;

      .hero-cta {
        align-items: center;
        display: flex;
        justify-content: center;
        background-color: $theme-color;
        height: 45px;
        border: none;
        border-radius: 2px;
        width: 200px;
        font-size: 16px;
        cursor: pointer;
        outline: none;
        color: var(--app-button-text);
        font-family: 'Plus Jakarta Sans', sans-serif;
        font-weight: 600;
        transition: opacity 0.2s 0.2s ease-in-out;
        position: relative;
        z-index: 1005;

        a {
          color: var(--app-button-text);
          text-decoration: none;
          display: flex;
          gap: 1rem;
          align-items: center;
        }

        &:hover {
          opacity: 0.75;
        }

        &:disabled {
          opacity: 0.5;
          cursor: not-allowed;
        }
      }

      .secondary-cta {
        align-items: center;
        display: flex;
        justify-content: center;
        border: 2px solid $theme-color;
        height: 45px;
        border-radius: 2px;
        width: 200px;
        font-size: 16px;
        cursor: pointer;
        outline: none;
        color: var(--app-seoncdary-button-text);
        font-family: 'Plus Jakarta Sans', sans-serif;
        font-weight: 600;
        transition: opacity 0.2s 0.2s ease-in-out;
        position: relative;
        z-index: 1005;

        a {
          color: var(--app-seoncdary-button-text);
          text-decoration: none;
          display: flex;
          gap: 1rem;
          align-items: center;
        }

        &:hover {
          opacity: 0.75;
        }

        &:disabled {
          opacity: 0.5;
          cursor: not-allowed;
        }
      }
    }

    .role-heading {
      font-size: 24px;
      font-weight: 600;
      // text-shadow: 0 0 15px var(--app-text-shadow);
      line-height: 1.5;
      // color: var(--app-sub-text);
      color: #313232;
      margin: 0;
    }

    .desc {
      line-height: 1.75;
      font-size: 18px;
      // color: var(--desc-text);
      color: #515252;
      font-weight: 400;
      font-family: 'Mulish', sans-serif;
    }

    .buttons-container {
      display: flex;

      .button-section {
        &:first-child {
          margin-right: 2rem;
        }
      }
    }
  }
}

.projectss-banner {
  max-width: $max-width;
  margin: 0 auto;
  background-color: var(--alternate-section-bg);
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 5px;
  padding: 3rem;

  .heading {
    font-size: 30px;
    font-weight: 600;
    text-shadow: 0 0 15px var(--app-text-shadow);
    line-height: 1.5;
    color: var(--app-text);
    margin: 0;
  }

  .desc {
    line-height: 1.75;
    font-size: 18px;
    color: var(--desc-text);
    font-weight: 300;
    margin: 0;
    font-family: 'Mulish', sans-serif;
  }

  .button-section {
    margin: 0;
  }
}

.services-section {
  max-width: $max-width;
  margin: 0 auto;

  .heading {
    margin: 0;
    font-size: 36px;
    font-weight: 600;
    color: var(--app-text);
    text-align: center;
  }

  .desc {
    color: var(--app-text);
    font-size: 18px;
    line-height: 1.75;
    font-weight: 300;
    max-width: 80%;
    margin: 2rem auto;
    font-family: 'Mulish', sans-serif;
    text-align: center;
  }

  .services-flex {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    margin-top: 60px;

    .service--card {
      width: 100%;
      margin: 0 15px;
      background-color: var(--alternate-section-bg);
      padding: 20px 10px;
      border-radius: 5px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      transition: transform 0.1s 0.1s ease-in-out;

      img {
        max-width: 100px;
        width: 100%;
        object-fit: cover;
        filter: invert(1);
        border-radius: 20px;
        padding: 10px;
        object-fit: cover;
      }

      .sc-heading {
        color: $theme-color;
        font-size: 20px;
        font-weight: 500;
        margin: 1rem auto 0;
        text-align: center;
      }

      .desc {
        color: var(--app-text);
        font-size: 18px;
        line-height: 1.75;
        font-weight: 300;
        max-width: 80%;
        margin: 1rem auto;
        font-family: 'Mulish', sans-serif;
        text-align: center;
      }

      &:first-child {
        margin-left: 0;
      }

      &:last-child {
        margin-right: 0;
      }

      &:hover {
        transform: scale(1.05);

        img {
          background: $theme-color;
          filter: unset;
        }
      }
    }
  }
}

.sk--contact-section {
  background-color: var(--body-background);
  max-width: $max-width;
  margin: 7rem auto 0;
  border-radius: 5px;

  .main--heading {
    margin: 0;
    font-size: 36px;
    font-weight: 600;
    color: var(--app-text);
  }

  .sk--contact-flex-section {
    display: flex;
    justify-content: space-between;
    padding: 20px 0;

    .sk--lside {
      flex: 0 0 40%;

      .desc {
        color: var(--app-text);
        font-size: 18px;
        line-height: 1.75;
        font-weight: 300;
        font-family: 'Mulish', sans-serif;
      }

      .email-section {
        margin-top: 16px;
        margin-bottom: 16px;

        p {
          color: var(--desc-text);
          margin: 0 0 5px;
          font-size: 14px;
          font-weight: 400;
        }

        a {
          color: $active-theme-color;
          text-decoration: none;
          font-weight: 600;
        }
      }

      .follow-section {
        margin-top: 40px;

        p {
          color: var(--desc-text);
          margin: 0 0 5px;
          font-weight: 500;
        }
      }
    }

    .sk--rside {
      flex: 0 0 40%;

      .thank-you-message {
        background: #009688;
        padding: 15px;
        border-radius: 5px;
        margin-bottom: 15px;

        h4 {
          margin: 0;
          font-weight: 600;
          line-height: 1.5;
        }
      }

      .error-message-section {
        background: #ef9a9a;
        padding: 15px;
        border-radius: 5px;
        margin-bottom: 15px;

        h4 {
          margin: 0;
          font-weight: 600;
          line-height: 1.5;
        }
      }

      .form-section {
        margin-bottom: 20px;

        input,
        textarea {
          width: 100%;
          background-color: var(--alternate-section-bg);
          height: 50px;
          border-radius: 2px;
          border: none;
          outline: none;
          font-size: 16px;
          color: var(--app-text);
          padding: 15px;
          font-family: 'Plus Jakarta Sans', sans-serif;
          font-weight: 500;
        }

        input.invalid-input,
        textarea.invalid-input {
          border: 2px solid #ef9a9a;
        }

        textarea {
          height: unset;
          resize: none;
        }
      }

      .button-section {
        text-align: right;
        position: relative;
      }
    }
  }
}


@media screen and (max-width: 767px) {
  .home-page {
    padding-left: 0rem;
  }

  .app-landing-section {
    margin-top: 1rem;

    .sub-heading {
      font-size: 14px;
    }

    .heading {
      font-size: 36px;
    }

    .desc {
      font-size: 16px;
    }

    .buttons-container {
      display: block;
      max-width: 210px;
      margin: auto;

      .button-section {
        &:first-child {
          margin-right: 0;
        }
      }
    }
  }

  .about-me-section {
    padding: 0;
    margin-top: 4rem;
    flex-wrap: wrap;
    margin-left: 1rem;
    margin-right: 1rem;

    .left-side {
      flex: 0 0 1;
      margin-right: 0;
      max-width: unset;
      position: relative;
      margin-bottom: 0px;
      border-radius: 0;

      img {
        border-radius: 0;
        width: 100%;
        position: relative;
        z-index: 1000;
        object-fit: fill;
        max-height: unset;

      }
    }

    .right-side {
      width: 100%;
      padding: 1rem;
      border-radius: 0;

      .heading {
        font-size: 24px;
      }

      .desc {
        font-size: 16px;
      }

      .role-heading {
        font-size: 16px;
      }

      .buttons-container {
        display: block;
        max-width: 210px;
        margin: auto;

        .button-section {
          &:first-child {
            margin-right: 0;
          }
        }
      }
    }
  }

  .projectss-banner {
    display: block;
    padding: 1.5rem;
    margin: 0 1rem;

    .heading {
      font-size: 28px;
    }

    .desc {
      font-size: 16px;
    }

    .button-section {
      width: 208px;
      margin-top: 1rem;
    }
  }

  .services-section {
    padding: 20px;

    .heading {
      font-size: 28px;
    }

    .desc {
      font-size: 16px;
    }

    .services-flex {
      margin-top: 30px;
      flex-wrap: wrap;

      .service--card {
        flex: 0 0 100%;
        margin: 0;
        margin-bottom: 20px;

        .sc-heading {
          font-size: 18px;
        }

        .desc {
          font-size: 16px;
        }
      }
    }
  }

  .sk--contact-section {
    margin: 20px;
    margin-top: 40px;

    .main--heading {
      font-size: 28px;
    }

    .social-icons-section {
      margin-bottom: 2rem;
    }

    .sk--contact-flex-section {
      flex-wrap: wrap;

      .sk--lside {
        flex: 0 0 100%;

        .desc {
          font-size: 16px;
        }

        .follow-section {
          margin-top: 40px;
          margin-bottom: 40px;
        }
      }

      .sk--rside {
        flex: 0 0 100%;
      }
    }
  }
}

:root[data-theme="dark"] {
  .services-section {
    .services-flex {
      .service--card {
        img {
          filter: unset !important;
        }
      }
    }
  }
}