@import '../../../constants.scss';

.app-footer {
  margin-top: 20px;
  background-color: var(--alternate-section-bg);
  padding: 1rem;
  .content {
    max-width: $max-width;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: auto;
    text-align: center;
    .desc {
      color: var(--desc-text);
      font-size: 16px;
      font-weight: 300;
    }
  }
}

@media screen and (max-width: 767px) {
  .app-footer {
    .content {
      flex-direction: column;
      text-align: center;
    }
  }
}